import { ChatActivate } from 'components/Chat/ChatActivate'
import { WebformMaggiorTutela } from 'components/WebformMaggiorTutela/WebformMaggiorTutela'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import { LayoutContextProvider } from 'contexts/LayoutContext'
import { useCpaEntityQuery } from 'hooks/use-cpa-entity'
import { useThankyouData } from 'hooks/use-thankyou-data'
import { Helmet } from 'react-helmet'

const MaggiorTutelaVolturaPage = () => {
  const pageTitle = 'Servizio di Maggior Tutela'
  const thankyouData = useThankyouData()
  const chatQuery = useCpaEntityQuery()

  return (
    <A2ADataContextProvider>
      <LayoutContextProvider>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ChatActivate chatQuery={chatQuery} />
        <WebformMaggiorTutela
          thankyouData={thankyouData}
          isVoltura
        ></WebformMaggiorTutela>
      </LayoutContextProvider>
    </A2ADataContextProvider>
  )
}

export default MaggiorTutelaVolturaPage
